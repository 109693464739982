<template>
  <div>
    <h1>sections Hotelgal.vue</h1>
  </div>
</template>

<script>
  export default {
    name: 'Hotelgal',
  }
</script>
